<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
          <CCardHeader tag="div">
            <CIcon name="cil-grid"/>
            Thêm mới ngành, lĩnh vực kêu gọi đầu tư
          </CCardHeader>
          <CCardBody>
            <CForm>
              <CRow>
                <CCol lg="6">
                  <ValidationProvider name="Tên ngành/lĩnh vực" rules="required" v-slot="{ errors, valid , touched }">
                    <BaseInput label="Tên ngành/lĩnh vực" horizontal :value.sync="item.tenNganhLinhVuc" :is-valid="valid"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
                <CCol lg="6">
                  <ValidationProvider name="Mã ngành ISIC2" rules="required" v-slot="{ errors, valid , touched }">
                    <BaseInput label="Mã ngành ISIC2" horizontal :value.sync="item.maNganhISIC" :is-valid="valid"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <CTextarea label="Mô tả" horizontal :value.sync="item.moTa"/>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <CRow>
                    <CCol tag="label" sm="3" class="col-form-label">Trạng thái</CCol>
                    <CCol sm="9" class="form-inline">
                      <CInputCheckbox label="Hoạt động" :checked.sync="item.trangThai"/>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CForm>
            <CElementCover v-if="isLoading"/>
          </CCardBody>
          <CCardFooter tag="div" class="text-right">
            <CButton color="secondary" class="mr-2" @click="cancel">Hủy bỏ</CButton>
            <CButton color="primary" @click="handleSubmit(saveItem)" :disabled="invalid || isSaving">Lưu lại</CButton>
          </CCardFooter>
        </ValidationObserver>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { statuses, loaiDonVis } from '@/shared/appConstants'
import { mapGetters, mapActions } from 'vuex'
import { CREATE_NGANHLINHVUCKEUGOIDAUTU } from '@/store/modules/nganh-linh-vuc-keu-goi-dau-tu/actionTypes'
import { tinhThanhService } from '@/services/tinh-thanh.service'

export default {
  name: 'NganhLinhVucKeuGoiDauTuCreate',
  data () {
    return {
      optionsTrangThai: statuses,
      optionsLoaiDonVi: loaiDonVis,
      item: {},
      optionsTinhThanh: [],
      isLoading: false
    }
  },
  computed: {
    ...mapGetters('nganhLinhVucKeuGoiDauTu', {
      isSaving: 'isSaving',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('nganhLinhVucKeuGoiDauTu', { createItem: CREATE_NGANHLINHVUCKEUGOIDAUTU }),
    async saveItem () {
      await this.createItem(this.item)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        await this.$router.push({ path: '/danh-muc/nganh-linh-vuc-keu-goi-dau-tu' })
      }
    },
    cancel () {
      this.$router.push({ path: '/danh-muc/nganh-linh-vuc-keu-goi-dau-tu' })
    }
  },
  async mounted () {
    this.isLoading = true
    this.optionsTinhThanh = await tinhThanhService.getAll()
    this.isLoading = false
  }
}
</script>
